<template>
  <div class="search-collection d-flex flex-column">
    <form @submit.prevent="searchProduct()">
      <div class="collections-container mb-1">
        <v-chip
          v-for="(tagSelected, index) in formData.tags"
          :key="`tag-selected-chip-${index}`"
          class="ma-1"
          color="teal"
          text-color="white"
          small
          label
          close
          @click:close="unSelectTag(index)">
          {{ tagSelected }}
        </v-chip>
      </div>
      <div class="d-flex align-center col-search">
        <v-row no-gutters>
          <v-col
            v-if="formData.brand"
            cols="12"
            md="4">
            <v-select
              v-model="formData.brand"
              class="mr-2"
              hide-details
              dense
              solo
              label="Brand"
              :items="allBrand"
              item-text="text"
              item-value="value">
            </v-select>
          </v-col>
          <v-col
            cols="12"
            :md="formData.brand ? 4 : 6">
            <v-autocomplete
              v-model="formData.gwCollection"
              class="mr-2"
              :items="collections"
              filled
              solo
              label="Collection"
              hide-details
              hide-selected
              item-value="id"
              item-text="name">
              <template v-slot:append>
                <v-icon x-small>
                  mdi-rhombus-split
                </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            :md="formData.brand ? 4 : 6">
            <v-autocomplete
              v-model="selectedTags"
              :items="autoCompleteTags"
              :search-input.sync="tagSearch"
              multiple
              filled
              solo
              label="Group Category"
              hide-details
              hide-selected>
              <template v-slot:append>
                <v-icon x-small>
                  mdi-gamepad-circle-outline
                </v-icon>
              </template>
              <template v-slot:selection>
                <span></span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <div class="categories-operation pl-2">
          <span class="text-caption mr-2">OR</span>
          <v-switch
            v-model="formData.tagOperation"
            class="ma-0"
            false-value="OR"
            true-value="AND"
            color="secondary"
            hide-details
            dense />
          <span class="text-caption">AND</span>
        </div>
        <v-btn
          class="ml-1"
          :min-width="0"
          :width="40"
          color="secondary"
          small
          type="submit">
          <v-icon x-small>
            mdi-magnify
          </v-icon>
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const ProductAttributeService = new ProductAttributeProvider()
export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all'
      })
    },
    tags: {
      type: Array,
      default: () => []
    },
    collections: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tagSearch: '',
      allBrand: []
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    autoCompleteTags () {
      return this.tags.map((tag) => tag.name)
    },
    selectedTags: {
      get () {
        return this.formData.tags
      },
      set (val) {
        this.formData.tags = val
        this.tagSearch = ''
      }
    }
  },
  async mounted () {
    await this.getBrand()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })
        this.allBrand = [
        {
          text: 'All Brand',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.formData.tags[index])
      if (tagIndex !== -1) {
        this.formData.tags.splice(index, 1)
      }
    },
    searchProduct () {
      this.$emit('on-search', this.formData)
    }
  }
}
</script>

<style scoped>
.col-search {
  width: 100%;
}
.search-collection {
  width: 100%;
}
.collections-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.categories-operation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.search-collection .v-input__control {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
.search-collection .v-input__slot {
  max-width: unset !important;
  min-height: 28px !important;
  max-height: 28px !important;
}
</style>
